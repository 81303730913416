import { DataType } from "@/modules/workflow-designer/constants"

export function useDataType() {
    const types: Record<DataType, { textColor: string, bgColor: string, borderColor?: string, label?: string }> = {
        double: { textColor: 'text-yellow-900', bgColor: "bg-yellow-300" },
        int: { textColor: 'text-green-900', bgColor: "bg-green-300", label: 'integer' },
        'int || double': { textColor: 'text-green-900', bgColor: "bg-green-300" },
        string: { textColor: 'text-neutral-900', bgColor: "bg-neutral-400" },
        datetime: { textColor: 'text-purple-900', bgColor: "bg-purple-300" },
        boolean: { textColor: 'text-blue-900', bgColor: "bg-blue-300" },
    }

    const getTextColor = (dataType: string) => types[dataType]?.textColor || 'text-white'
    const getBgColor = (dataType: string) => types[dataType]?.bgColor || 'bg-primary-600'
    const getBorderColor = (dataType: string) => types[dataType]?.borderColor || 'border-primary-600'
    const getLabel = (dataType: string) => types[dataType]?.label || dataType

    return { getLabel, getTextColor, getBgColor, getBorderColor }
}