













































import { defineComponent, ref, computed } from '@vue/composition-api';
import store from '@/app/store';
import PageHeader from './PageHeader.vue';
import SideMenu from './SideMenu.vue';

export default defineComponent({
    name: 'BaseLayout',
    components: {
        PageHeader,
        SideMenu,
    },
    setup() {
        const mobileMenuOpen = ref(false);
        const isAuthenticated = computed(() => store.getters.auth.isAuthenticated);

        return {
            mobileMenuOpen,
            isAuthenticated,
        };
    },
});
