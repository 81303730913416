




































































































import { defineComponent, computed, watch } from '@vue/composition-api';
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
} from '@vue-hero-icons/solid';
import { usePagination } from 'vue-composable';

export default defineComponent({
    name: 'Pagination',
    components: {
        ChevronLeftIcon,
        ChevronRightIcon,
        ChevronDoubleLeftIcon,
        ChevronDoubleRightIcon,
    },
    model: {
        prop: 'currentPage',
        event: 'change',
    },

    props: {
        currentPage: {
            type: Number,
            default: 1,
        },
        pageSize: {
            type: Number,
            default: 10,
        },
        total: {
            type: Number,
            default: 0,
        },
        startVisiblePages: {
            type: Number,
            default: 2,
        },
        endVisiblePages: {
            type: Number,
            default: 2,
        },
        containerClasses: {
            type: String,
            default: 'px-4 py-3 sm:px-6 border-t border-neutral-200 bg-white',
        },
        beginAndEndArrows: {
            type: Boolean,
            default: true,
        },
    },
    setup(props, { emit }) {
        const { currentPage, next, prev, lastPage } = usePagination({
            currentPage: props.currentPage,
            pageSize: props.pageSize,
            total: computed(() => props.total || 0) as any,
        });

        const totalPages = computed(() => lastPage.value);

        const startResult = computed(() => {
            if (props.currentPage === 1) {
                return 1;
            }

            return (props.currentPage - 1) * props.pageSize + 1;
        });

        const endResult = computed(() =>
            startResult.value + props.pageSize > props.total ? props.total : startResult.value + props.pageSize - 1,
        );

        const visiblePages = computed(() => {
            const pages = [];
            if (props.startVisiblePages + props.endVisiblePages >= totalPages.value) {
                for (let p = 1; p <= totalPages.value; p++) {
                    pages.push({
                        value: p,
                        label: p,
                    });
                }
                return pages;
            }

            if (props.currentPage - 1 > props.startVisiblePages) {
                pages.push({
                    value: null,
                    label: '...',
                });
            }

            for (let p = props.currentPage - props.startVisiblePages; p < props.currentPage; p++) {
                if (p > 0) {
                    pages.push({
                        value: p,
                        label: p,
                    });
                }
            }

            pages.push({
                value: props.currentPage,
                label: props.currentPage,
            });

            for (let p = props.currentPage + 1; p <= props.currentPage + props.endVisiblePages; p++) {
                if (p <= totalPages.value) {
                    pages.push({
                        value: p,
                        label: p,
                    });
                }
            }

            if (props.currentPage + 1 < totalPages.value - props.endVisiblePages + 1) {
                pages.push({
                    value: null,
                    label: '...',
                });
            }

            return pages;
        });

        const goTo = (page: number) => {
            if (page !== null) {
                emit('change', page);
            }
        };

        watch(
            () => currentPage.value,
            (page: number) => {
                emit('change', page);
            },
        );

        return {
            next,
            prev,
            totalPages,
            startResult,
            endResult,
            visiblePages,
            goTo,
        };
    },
});
