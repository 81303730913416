import { useLocations, useModelBrowser } from '@/app/composable';
import router from '@/app/router';
import store from '@/app/store';
import Auth from '@/modules/auth/api/auth';
import { useOrganisation } from '@/modules/organization/composable';
import { UserStatus } from '@/modules/organization/constants';
import Keycloak from 'keycloak-js';
import * as R from 'ramda';
import { Route } from 'vue-router';

let keycloak: Keycloak;

export function useKeycloak() {
    const isEnabled = () => {
        return !R.isNil(keycloak);
    };

    const isAuthenticated = () => {
        return !!keycloak?.authenticated;
    };

    const getKeycloakToken = () => {
        return keycloak?.idToken;
    };

    const initializeKeycloak = async (redirectUri?: string) => {
        if (!keycloak) return;
        return new Promise((resolve, reject) => {
            keycloak
                .init({ onLoad: 'login-required', checkLoginIframe: false, redirectUri })
                .then(() => {
                    resolve(true);
                })
                .catch(() => {
                    reject(new Error('Authenticated Failed'));
                });
        });
    };

    const createKeycloakInstance = () => {
        keycloak = new Keycloak({
            url: process.env.VUE_APP_KEYCLOAK_URL,
            realm: process.env.VUE_APP_KEYCLOAK_REALM || '',
            clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID || '',
        });
    };

    const createAndInitializeKeycloak = async (redirectUri?: string) => {
        createKeycloakInstance();
        return initializeKeycloak(redirectUri);
    };

    const login = async (to?: Route | null | undefined) => {
        if (!keycloak) return;

        return new Promise((resolve, reject) => {
            if (!isAuthenticated())
                resolve(
                    keycloak
                        .init({ onLoad: 'login-required', checkLoginIframe: false })
                        .then(async () => {
                            return afterLogin(to);
                        })
                        .catch(() => {
                            reject(new Error('Authenticated Failed'));
                        }),
                );
            else resolve(afterLogin(to));
        });
    };

    const afterLogin = async (to?: Route | null | undefined) => {
        return new Promise((resolve, reject) => {
            Auth.keycloakLogin(keycloak.subject, keycloak.idToken, keycloak.refreshToken)
                .then(async (res: { data: { isNewUser: boolean } }) => {
                    if (res.data.isNewUser) await createAndInitializeKeycloak();
                    const { data } = await Auth.user();
                    store.commit.auth.SET_USER(data);
                    // Users that register for the first time in the platform without organization
                    if (data.status === UserStatus.Pending && !data.organisationId) {
                        router.push({ name: 'organization-registration' });
                        // Users that have already register an organization and waiting for verification
                    } else if (data.status === UserStatus.Pending && data.organisationId) {
                        router.push({ name: 'unverified-organization' });
                    } else {
                        useModelBrowser().initialize();
                        useLocations().initialize();
                        if (data.organisationId) useOrganisation(data.organisationId).initialize();
                        store.dispatch.notificationEngine.fetchNotifications();
                        store.dispatch.executionVersions.loadVersions();
                        store.dispatch.executionErrors.loadExecutionErrors();
                        store.dispatch.dataModel.loadDomains();
                    }
                    if (to && to.name && data.organisationId && data.status === UserStatus.Active) {
                        router.push({ name: to.name, params: to.params });
                    }

                    resolve(true);
                })
                .catch((err) => {
                    if (err.response?.data) {
                        if (err.response.data.statusCode === 403) reject(new Error('The account is not activated'));
                        if (err.response.data.message) reject(new Error(err.response.data.message));
                    }
                    if (err.message) reject(new Error(err.message));
                    reject(new Error('Failed to login using keycloak'));
                });
            resolve(true);
        });
    };

    const logout = async (redirectUri?: string) => {
        if (!keycloak) return;
        return keycloak.logout({ redirectUri });
    };

    const register = async () => {
        if (!keycloak) return;
        if (!isAuthenticated()) await keycloak.init({});
        await keycloak.register();
    };

    const updateToken = async () => {
        keycloak
            //update token if its near to expire <360 seconds => <6 min
            .updateToken(180)
            .then(function (refreshed: any) {
                if (refreshed) {
                    // Token has been successfully refreshed (future usage)
                } else {
                    // Token is still valid
                }
            })
            .catch(function () {
                new Error('Failed to refresh the token, or the session has expired');
            });
    };

    if (
        R.isNil(keycloak) &&
        !R.isNil(process.env.VUE_APP_KEYCLOAK_URL) &&
        !R.isEmpty(process.env.VUE_APP_KEYCLOAK_URL) &&
        !R.isNil(process.env.VUE_APP_KEYCLOAK_REALM) &&
        !R.isEmpty(process.env.VUE_APP_KEYCLOAK_REALM) &&
        !R.isNil(process.env.VUE_APP_KEYCLOAK_CLIENT_ID) &&
        !R.isEmpty(process.env.VUE_APP_KEYCLOAK_CLIENT_ID)
    )
        createKeycloakInstance();

    return {
        isEnabled,
        isAuthenticated,
        login,
        logout,
        getKeycloakToken,
        register,
        updateToken,
        createAndInitializeKeycloak,
        initializeKeycloak,
    };
}
